import { createStore } from 'vuex'
import { get, put, del } from 'aws-amplify/api';

export default createStore ({
    namespaced: true,
    state:{
        links: [],
        personLinks: []
    },
    getters:{
        sections(state){
            let sections = [];
            state.links.forEach(affiliateLink => {
                let section = {
                    SectionID: affiliateLink.SectionID,
                    SectionTitle: affiliateLink.SectionTitle,
                    SectionDescription: affiliateLink.SectionDescription,
                    SectionOrder: affiliateLink.SectionOrder
                }
                let preExistingSection = sections.find(sectionToCheck => sectionToCheck.SectionID === section.SectionID );
                if(preExistingSection === undefined){
                    sections.push(section)
                }
            });
            return sections
        },
        personLinks(state){
            console.log(state.personLinks);
            return state.personLinks
        }
    },
    mutations:{
        SET_AFFILIATE_LINKS(state, data){
            state.links = data;
        },
        SET_PERSON_LINKS(state, data){
            state.personLinks = data;
        }
    },
    actions:{
        async putAffiliateLink({dispatch},  payload){
            const restOperation = put({
                    apiName : "affiliatemanagerapi",
                    path: "/put/link",
                    options:{
                        body: payload
                    }
            })
                const { body } = await restOperation.response;
                body.json().then(() => {
                    dispatch("getLinksByPersonType", payload.personType);
                })
        },
        async getLinksByPersonType({state, commit}, personType){
            const restOperation = get(
                {
                    apiName : "affiliatemanagerapi",
                    path: "/get/links/all"
                });
                const { body } = await restOperation.response;
                body.json().then((data) => {
                commit("SET_AFFILIATE_LINKS", data.body)
                    let personTypeLinks = state.links.filter(affiliateLink => affiliateLink.ApplicablePersonTypes.includes(personType));
                    let sections = [];
                    personTypeLinks.forEach(affiliateLink => {
                        let section = {
                            SectionID: affiliateLink.SectionID,
                            SectionTitle: affiliateLink.SectionTitle,
                            SectionDescription: affiliateLink.SectionDescription,
                            SectionOrder: affiliateLink.SectionOrder,
                            Links: []
                        }
                        let preExistingSection = sections.find(sectionToCheck => sectionToCheck.SectionID === section.SectionID );
                        if(preExistingSection === undefined){
                            sections.push(section)
                        }
                        let index = sections.findIndex(sectionToCheck => sectionToCheck.SectionID === section.SectionID);
                        sections[index].Links.push(
                            {
                                ListingID: affiliateLink.ListingID,
                                ListingOrder: affiliateLink.ListingOrder,
                                ListingDescription: affiliateLink.ListingDescription,
                                ListingTitle: affiliateLink.ListingTitle,
                                ListingImageUrl: affiliateLink.ListingImageUrl,
                                ListingAffiliateUrl: affiliateLink.ListingAffiliateUrl,
                                ApplicablePersonTypes: affiliateLink.ApplicablePersonTypes
                            }
                        )
                    })
                    sections = sections.sort((a, b) => a.SectionOrder - b.SectionOrder)
                    sections.forEach(section => {
                        section.Links.sort((a, b) => a.ListingOrder - b.ListingOrder)
                    })
            commit("SET_PERSON_LINKS", sections)
            } );
        },
        async deleteAffiliateLink({dispatch}, payload){
            try {
                const restOperation = del({
                apiName: 'affiliatemanagerapi',
                path: `/delete/link/${payload.listingID}`
                });
                const {body} = await restOperation.response;
                body.json().then(() => {
                dispatch("getLinksByPersonType", payload.personType);
                })
            } 
            catch (e) {
                console.log('DELETE call failed: ', JSON.parse(e.response.body));
            }
        }
    },
    modules:{

    }
})