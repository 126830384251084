import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  // Generic Route
  {
    path: '/', 
    name: 'Home',
    component: () => import('../views/HomeView'),
  },
  {
    path: '/:personType/affiliate-links', 
    name: 'PersonView',
    component: () => import('../views/PersonView'),
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
