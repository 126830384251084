<template>
<v-app>
  <v-app-bar :elevation="2" class="bg-primary">
    <template v-slot:prepend>
      <img style="height:100px; width: auto;" :src='require("/src/assets/AgentOS-Logo.png")' />
    </template>
    <template v-slot:append v-if="auth !== undefined && auth.authStatus === 'authenticated'">
      <div>
        <v-btn @click="navHome()">Home</v-btn>
        <v-btn @click="signOut()">Sign Out</v-btn>
      </div>
    </template>
  </v-app-bar>
  <div id="main">
    <v-main>
    <!--Login code-->
      <div v-if="auth !== undefined && auth.authStatus !== 'authenticated'">
        <authenticator :hideSignUp="true">
          <template v-slot:header>
            <div style="padding: var(--amplify-space-large); text-align: center">
            <img
            class="amplify-image"
            alt="Amplify logo"
            src="../src/assets/AgentOS-Logo.png"
            />
            </div>
            <br />
          </template>
          <template v-slot:sign-in-header>
            <h3
            class="amplify-heading"
            style="padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl)"
            >
            Sign in to the agentOS affiliate management app
            </h3>
          </template>

        </authenticator>
      <!--Login code ends-->
      </div>
      <div v-else>
        <router-view />
      </div>
    </v-main>
  </div>
</v-app>

</template>

<script>
import { Authenticator } from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";
import { useAuthenticator } from '@aws-amplify/ui-vue';

import { defineComponent } from "vue";
export default defineComponent({
  name: 'App',
  components:{
    Authenticator
  },
  async mounted(){
    this.auth = useAuthenticator();
  },
  created(){
  },
  data(){
    return {
      auth:undefined
    }
  },
  methods:{
    async signOut(){
            try{
                await this.auth.signOut();
            }
            catch(error){
                console.log("failed to log out: ", error)
            }
        },
        navHome(){
          this.$router.push('/')
        }
  }
})
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
